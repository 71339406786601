<template>
  <div class="text-sm">
    <div class="bg-[#273034]">
      <div
        class="max-w-screen-sm px-10 py-6 mx-auto text-white sm:px-20 2xl:px-24"
      >
        <ul class="flex items-center justify-between">
          <li v-for="social in socials" :key="social.title">
            <a href="#">
              <img :src="social.icon" :alt="social.title" class="h-6" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="bg-white">
      <div
        class="flex flex-wrap justify-between max-w-screen-xl px-2 py-6 mx-auto space-y-4 sm:px-10 2xl:px-24 sm:space-y-0"
      >
        <div>&copy;Adey Meselesh GmbH, {{ new Date().getFullYear() }}</div>
        <div>
          <ul class="flex flex-wrap space-x-8">
            <li>
              <router-link to="/terms-of-use">Imprint</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">Privacy policy</router-link>
            </li>
            <li>
              <router-link to="/contact-us">Contact us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socials: [
        {
          icon: require("../../assets/images/socials/linkedin.png"),
          title: "linkedin",
        },
        {
          icon: require("../../assets/images/socials/youtube.png"),
          title: "youtube",
        },
        {
          icon: require("../../assets/images/socials/twitter.png"),
          title: "twitter",
        },
        {
          icon: require("../../assets/images/socials/fb.png"),
          title: "facebook",
        },
        {
          icon: require("../../assets/images/socials/instagram.png"),
          title: "instagram",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
