<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/3">
        <h2 class="text-2xl font-bold">
          We support you with our
          <span class="text-dfm-text">DIGITAL PRODUCTS</span>
        </h2>
        <p class="mt-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit a nobis
          vero inventore eius. Placeat blanditiis vitae consequuntur aliquid
          labore harum itaque officia. Aspernatur, quas praesentium. Optio quo
          cumque veniam?
        </p>
      </div>
      <div class="w-full mt-10 lg:pl-20 lg:w-2/3 lg:mt-0">
        <div class="grid grid-cols-2 gap-10 place-items-center">
          <div
            v-for="product in products"
            :key="product.title"
            class="flex flex-col justify-center"
          >
            <img :src="product.image" :alt="product.title" />
            <h3 class="mt-3 font-medium text-dfm-menu">
              {{ product.title }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          image: require("../../assets/images/branches/banking.jpg"),
          title: "Banking and Financial Services",
        },
        {
          image: require("../../assets/images/branches/healthcare.jpg"),
          title: "HealthCare",
        },
        {
          image: require("../../assets/images/branches/public sector.jpg"),
          title: "Public Sector",
        },
        {
          image: require("../../assets/images/branches/transportation.jpg"),
          title: "Transportation",
        },
        {
          image: require("../../assets/images/branches/retail.jpg"),
          title: "Retail",
        },
        {
          image: require("../../assets/images/branches/agriculture.jpg"),
          title: "Agriculture",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
