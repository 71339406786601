<template>
  <div
    class="max-w-screen-md px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div
      class="flex flex-col space-y-3 text-2xl font-bold text-center uppercase sm:space-y-6"
    >
      <h2>We serve <span class="text-dfm-text">your business purpose</span></h2>
      <h2>
        We provide
        <span class="text-dfm-text"> Technology for your business </span>
      </h2>
      <h2>
        We <span class="text-dfm-text">consult, create and develop </span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
