<template>
  <div>
    <IntroServe />
    <Branches />
    <Functions />
  </div>
</template>

<script>
import IntroServe from "@/components/whoweserve/IntroServe.vue";
import Branches from "@/components/whoweserve/BranchesModule.vue";
import Functions from "@/components/whoweserve/FunctionsModule.vue";
export default {
  components: {
    IntroServe,
    Branches,
    Functions,
  },
};
</script>

<style lang="scss" scoped></style>
