<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div class="lg:flex">
      <div class="mb-8 lg:pr-20">
        <h2 class="text-2xl font-bold">
          Together we reach
          <span class="text-dfm-text">your business goals</span>
        </h2>
        <p class="mt-3">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
          dolore maxime, quibusdam neque voluptates quam voluptatum perferendis,
          architecto natus adipisci tempore voluptas, ut consequuntur atque ad
          labore quasi quae tenetur.
        </p>
        <button
          class="px-4 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          Who we serve
        </button>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-full lg:w-[400px] bg-zinc-200 rounded"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
