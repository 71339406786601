<template>
  <div>
    <div
      class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
    >
      <div class="max-w-screen-md mx-auto border-b border-b-gray-200">
        <h1 class="text-2xl font-bold text-center uppercase">Contact us</h1>
        <p class="mt-4">
          We support our clients in their journey of digital transformation and
          to mature in thier profession. Whatever we do, we do it with
          expertise, passion and quality. Your project is our project and
          together we create great solutions..
        </p>
      </div>
      <div
        class="max-w-screen-md px-2 py-20 mx-auto border-b border-b-gray-200"
      >
        <div class="mb-8 lg:pr-10">
          <h2 class="font-bold sm:text-xl xl:text-2xl">
            To get going please
            <span class="text-dfm-text">CONTACT US</span>
          </h2>
          <p class="mt-3">
            Whenever you have questions or need further information, simply
            approach us with your name and your question. We will reply as as
            possible.
          </p>
        </div>
        <div class="">
          <form>
            <div class="flex flex-col space-y-6">
              <div
                class="flex flex-col space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-10"
              >
                <div class="flex flex-col w-full space-y-1">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                  />
                </div>
                <div class="flex flex-col w-full space-y-1">
                  <label for="">Company</label>
                  <input
                    type="text"
                    class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                  />
                </div>
                <div class="flex flex-col w-full space-y-1">
                  <label for="">Surname</label>
                  <input
                    type="text"
                    class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                  />
                </div>
                <div class="flex flex-col w-full space-y-1">
                  <label for="">Phone number</label>
                  <input
                    type="text"
                    class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                  />
                  <span class="text-xs">Optional, if we should call you</span>
                </div>
                <div class="flex flex-col w-full space-y-1">
                  <label for="">Email address</label>
                  <input
                    type="text"
                    class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                  />
                </div>
              </div>
              <div class="flex flex-col w-full space-y-1">
                <label for="">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="320"
                  rows="5"
                  class="bg-[#f2ecec] px-3 py-2 border rounded border-zinc-300"
                />
              </div>

              <div class="flex items-center">
                <input
                  type="checkbox"
                  id="terbg-[#f2ecec] ms-checkbox"
                  value="terms-checked"
                  class="flex-shrink-0 w-5 h-5 rounded accent-dfm-text focus:accent-dfm-text"
                />
                <label
                  for="terms-checkbox"
                  class="ml-2 text-sm text-gray-900 dark:text-gray-300"
                >
                  I've read the privacy policy. I agree that my contact details
                  and questions will be stored permanently.
                </label>
              </div>
            </div>
            <div class="mt-14">
              <button
                class="px-10 py-2 text-white rounded bg-dfm-text hover:bg-orange-700"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
