<template>
  <nav>
    <IntroPromise />
    <IntroRight />
    <Products />
    <Services />
    <ContactUs />
    <Partners />
  </nav>
</template>

<script>
import IntroPromise from "@/components/home/IntroPromise.vue";
import IntroRight from "@/components/home/IntroRight.vue";
import Products from "@/components/home/ProductsModule.vue";
import Services from "@/components/home/ServicesModule.vue";
import Partners from "@/components/home/PartnersModule.vue";
import ContactUs from "@/components/home/ContactUs.vue";
export default {
  components: {
    IntroPromise,
    IntroRight,
    Products,
    Services,
    Partners,
    ContactUs,
  },
};
</script>

<style lang="scss" scoped></style>
