<template>
  <div class="bg-[#f2ecec]">
    <div
      class="py-20 px-2 sm:px-10 2xl:px-24 mx-auto max-w-screen-xl border-b border-b-gray-200"
    >
      <div class="lg:flex">
        <div class="lg:pr-10 mb-8 lg:w-2/5">
          <h2 class="font-bold sm:text-xl xl:text-2xl">
            To get going please
            <span class="text-dfm-text">CONTACT US</span>
          </h2>
          <p class="mt-3">
            Whenever you have questions or need further information, simply
            approach us with your name and your question. We will reply as as
            possible.
          </p>
        </div>
        <div class="lg:w-3/5 bg-[#f6f2f1] p-6">
          <form>
            <div class="flex flex-col space-y-6">
              <div
                class="flex flex-col space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-10"
              >
                <div class="w-full flex flex-col space-y-1">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="px-3 py-2 border border-zinc-300 rounded"
                  />
                </div>
                <div class="w-full flex flex-col space-y-1">
                  <label for="">Company</label>
                  <input
                    type="text"
                    class="px-3 py-2 border border-zinc-300 rounded"
                  />
                </div>
                <div class="w-full flex flex-col space-y-1">
                  <label for="">Surname</label>
                  <input
                    type="text"
                    class="px-3 py-2 border border-zinc-300 rounded"
                  />
                </div>
                <div class="w-full flex flex-col space-y-1">
                  <label for="">Phone number</label>
                  <input
                    type="text"
                    class="px-3 py-2 border border-zinc-300 rounded"
                  />
                  <span class="text-xs">Optional, if we should call you</span>
                </div>
                <div class="w-full flex flex-col space-y-1">
                  <label for="">Email address</label>
                  <input
                    type="text"
                    class="px-3 py-2 border border-zinc-300 rounded"
                  />
                </div>
              </div>
              <div class="w-full flex flex-col space-y-1">
                <label for="">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="320"
                  rows="5"
                  class="border border-zinc-300 rounded px-3 py-2"
                />
              </div>

              <div class="flex items-center">
                <input
                  type="checkbox"
                  id="terms-checkbox"
                  value="terms-checked"
                  class="h-5 w-5 flex-shrink-0 rounded accent-dfm-text focus:accent-dfm-text"
                />
                <label
                  for="terms-checkbox"
                  class="ml-2 text-sm text-gray-900 dark:text-gray-300"
                >
                  I've read the privacy policy. I agree that my contact details
                  and questions will be stored permanently.
                </label>
              </div>
            </div>
            <div class="mt-14">
              <button
                class="px-10 py-2 bg-dfm-text rounded text-white hover:bg-orange-700"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
