<template>
  <div>
    <IntroModule />
    <FactsCompany />
    <OurValues />
  </div>
</template>

<script>
import IntroModule from "@/components/about-us/IntroModule.vue";
import FactsCompany from "@/components/about-us/FactsCompany.vue";
import OurValues from "@/components/about-us/OurValues.vue";
export default {
  components: {
    IntroModule,
    FactsCompany,
    OurValues,
  },
};
</script>

<style lang="scss" scoped></style>
