<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <h2 class="text-2xl font-bold">
      We support you with our
      <span class="text-dfm-text">DIGITAL PRODUCTS</span>
    </h2>
    <div class="grid grid-cols-2 md:flex md:justify-between place-items-center">
      <div
        v-for="product in products"
        :key="product.title"
        class="flex flex-col items-center justify-center mt-10"
      >
        <img :src="product.image" :alt="product.title" class="w-20 h-20" />
        <h3 class="mt-3 font-medium text-center">{{ product.title }}</h3>
      </div>
    </div>
    <div class="grid grid-cols-1 mt-20 place-items-center">
      <button
        class="px-4 py-2 text-white rounded bg-dfm-text hover:bg-orange-700"
      >
        Digital products
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          image: require("../../assets/images/products/erp.png"),
          title: "ERP SYSTEM",
        },
        {
          image: require("../../assets/images/products/appointment.png"),
          title: "APPOINTEMNT",
        },
        {
          image: require("../../assets/images/products/email.png"),
          title: "MAILING SYSTEM",
        },
        {
          image: require("../../assets/images/products/privacy-generator.png"),
          title: "POLICY GENERATOR",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
