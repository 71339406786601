<template>
  <div>
    <IntroModule />
    <ServicesModule />
  </div>
</template>

<script>
import IntroModule from "@/components/servicepage/IntroModule.vue";
import ServicesModule from "@/components/servicepage/ServicesModule.vue";
export default {
  components: {
    IntroModule,
    ServicesModule,
  },
};
</script>

<style lang="scss" scoped></style>
