<template>
  <div class="max-w-4xl px-5 py-20 mx-auto text-body-color">
    <div class="text-sm legals">Privacy policy</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
