import { render, staticRenderFns } from "./ProductsModule.vue?vue&type=template&id=774e1681&scoped=true&"
import script from "./ProductsModule.vue?vue&type=script&lang=js&"
export * from "./ProductsModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774e1681",
  null
  
)

export default component.exports