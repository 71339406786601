<template>
  <div
    class="max-w-screen-md px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <h1 class="text-2xl font-bold text-center uppercase">Digital Products</h1>
    <p class="mt-4">
      We support our clients in their journey of digital transformation and to
      mature in their profession. Whatever we do, we do it with expertise;
      passion and quality. Your project is our project and together we create
      great solutions.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
