<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <h1 class="text-2xl font-bold text-center capitalize">
      Select the Products
    </h1>
    <div
      class="flex flex-wrap items-center justify-between pt-6 text-xl font-bold text-center uppercase"
    >
      <h2 class="px-5 py-10">
        ADEY <span class="text-dfm-text">APPOINTMENT</span>
      </h2>
      <h2 class="px-5 py-10">
        ADEY
        <span class="text-dfm-text">ERP</span>
      </h2>
      <h2 class="px-5 py-10">
        ADEY <span class="text-dfm-text">MAIL SERVICE</span>
      </h2>
      <h2 class="px-5 py-10">
        ADEY <span class="text-dfm-text">GENERATOR</span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
