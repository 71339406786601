<template>
  <div
    class="max-w-screen-md px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <h1 class="text-2xl font-bold text-center uppercase">Who we serve</h1>
    <p class="mt-4">
      We have a lot of experience in different branches and to really understand
      your business and the challenges is important to create the tailer-made
      solution for your company.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
