<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div class="grid grid-cols-1 gap-14 md:gap-10 md:grid-cols-4">
      <div class="flex flex-col items-center justify-center">
        <img
          src="../../assets/images/serviceImages/consulting.png"
          alt="product.title"
          class="w-28 h-28"
        />
        <h3 class="mt-3 font-bold text-center">CONSULTING</h3>
      </div>
      <div class="col-span-2">
        <p>
          We help you generate more revenue through a focused strategy. We
          develop a strategy to achieve your business goals that is aligned with
          your objectives and use cases and based on your individual status quo.
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about consulting
        </button>
      </div>
      <div>
        <h2 class="font-semibold text-dfm-text">Our Consulting Portfolio:</h2>
        <ul class="mt-4 space-y-2 text-sm font-bold">
          <li>Business Development Ethiopia and Europe</li>
          <li>Market Entry Strategy Europe</li>
          <li>IT and Cyber Security</li>
          <li>Agile Project Management</li>
          <li>Leadership and Team Coaching</li>
        </ul>
      </div>
    </div>
    <div class="grid grid-cols-1 my-32 gap-14 md:gap-10 md:grid-cols-4">
      <div class="flex flex-col items-center justify-center">
        <img
          src="../../assets/images/serviceImages/digital marketing.png"
          alt="product.title"
          class="w-28 h-28"
        />
        <h3 class="mt-3 font-bold text-center">
          DITIGAL MARKETING AND BRANDING
        </h3>
      </div>
      <div class="col-span-2">
        <p>
          We develop powerful touchpoints across the entire digital customer
          journey. From the website with appropriate landing pages and
          configurators, to emails and blogs with helpful posts, to social media
          with engagement-boosting posts.
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Digital Marketing
        </button>
      </div>
      <div>
        <h2 class="font-semibold text-dfm-text">
          Our Digital Marketing and Branding Portfolio:
        </h2>
        <ul class="mt-4 space-y-2 text-sm font-bold">
          <li>Digital Marketing Strategy</li>
          <li>Market Research</li>
          <li>Branding</li>
          <li>Content Marketing</li>
          <li>Social Media</li>
          <li>Organization of Events</li>
          <li>Promotional Items</li>
        </ul>
      </div>
    </div>
    <div class="grid grid-cols-1 my-32 gap-14 md:gap-10 md:grid-cols-4">
      <div class="flex flex-col items-center justify-center">
        <img
          src="../../assets/images/serviceImages/it service.png"
          alt="product.title"
          class="w-28 h-28"
        />
        <h3 class="mt-3 font-bold text-center">IT SERVICES</h3>
      </div>
      <div class="col-span-2">
        <p>
          We see great added value in the digitalisation of your business. Bring
          your products to the digital market, reach a larger audience and
          become independent of the analogue market.
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Digital Marketing
        </button>
      </div>
      <div>
        <h2 class="font-semibold text-dfm-text">Our IT Services Portfolio:</h2>
        <ul class="mt-4 space-y-2 text-sm font-bold">
          <li>Website Development</li>
          <li>App Development</li>
          <li>Webshop Development</li>
          <li>Smart Contract Development & Audit</li>
          <li>IT Security Assessment</li>
          <li>Installation of Anti-Virus, Firewalls & SIEM</li>
        </ul>
      </div>
    </div>
    <div class="grid grid-cols-1 my-32 gap-14 md:gap-10 md:grid-cols-4">
      <div class="flex flex-col items-center justify-center">
        <img
          src="../../assets/images/serviceImages/iso certificaiton training.png"
          alt="product.title"
          class="w-28 h-28"
        />
        <h3 class="mt-3 font-bold text-center">ISO CERTIFICATION TRAININGS</h3>
      </div>
      <div class="col-span-2">
        <p>Certified Body from the Ethiopian Standard Authority (ESA).</p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about ISO Certification Training
        </button>
      </div>
      <div>
        <h2 class="font-semibold text-dfm-text">
          Our ISO Certification Training Portfolio:
        </h2>
        <ul class="mt-4 space-y-2 text-sm font-bold">
          <li>Training ISO 9001</li>
          <li>Training ISO xXXXX</li>
          <li>Training ISO xXXXX</li>
          <li>Training ISO xXXXX</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
