<template>
  <div>
    <div
      class="top-0 z-50 px-24 py-2 mx-auto border-b max-w-screen-2xl backdrop-blur-md"
    >
      <router-link to="/">
        <img
          src="../assets/adey-logo.webp"
          alt="adey logo"
          class="h-[74px] w-[150px]"
        />
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
