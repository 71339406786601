<template>
  <div class="bg-[#d3003f]">
    <div
      class="py-6 px-2 sm:px-10 2xl:px-24 mx-auto max-w-screen-xl text-white"
    >
      <h2 class="font-bold">BRANDS who worked with us</h2>
      <div class="flex justify-between flex-wrap">
        <div
          v-for="i in 6"
          :key="i"
          class="mt-6 flex flex-col justify-center items-center"
        >
          <img :src="brands.image" :alt="brands.title" class="h-10" />
          <h3 class="mt-3 font-medium text-center">{{ brands.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brands: {
        image: require("../../assets/images/unzer.png"),
        name: "unzer",
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
