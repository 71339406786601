<template>
  <div>
    <NavBar />
    <router-view />
    <FooterBar />
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import FooterBar from "@/components/common/FooterBar.vue";
export default {
  name: "MainLayout",
  components: {
    NavBar,
    FooterBar,
  },
};
</script>

<style></style>
