import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import HomeView from "@/views/HomeView.vue";
import DfmProducts from "@/views/DfmProducts.vue";
import DfmServices from "@/views/DfmServices.vue";
import WhoWeServe from "@/views/WhoWeServe.vue";
import ContactUs from "@/views/ContactUs.vue";
import AboutUs from "@/views/AboutUs.vue";
import LegalsLayout from "@/layouts/LegalsLayout.vue";
import DfmPrivacyPolicy from "@/views/DfmPrivacyPolicy.vue";
import Imprint from "@/views/DfmImprint.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/who-we-serve",
        name: "WhoWeServe",
        component: WhoWeServe,
      },
      {
        path: "/services",
        name: "DfmServices",
        component: DfmServices,
      },
      {
        path: "/products",
        name: "DfmProducts",
        component: DfmProducts,
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
      },
    ],
  },
  {
    path: "/legals",
    component: LegalsLayout,
    children: [
      {
        path: "/privacy-policy",
        name: "Privacy",
        component: DfmPrivacyPolicy,
      },
      {
        path: "/terms-of-use",
        name: "Terms",
        component: Imprint,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
