<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div class="flex flex-col lg:flex-row">
      <div class="w-full mt-10 lg:w-2/3 lg:mt-0">
        <div class="grid grid-cols-2 gap-10 lg:grid-cols-3 place-items-center">
          <div
            v-for="product in functions"
            :key="product.title"
            class="flex flex-col justify-center"
          >
            <img :src="product.image" :alt="product.title" />
            <h3 class="mt-3 font-medium text-dfm-menu">
              {{ product.title }}
            </h3>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/3 lg:pl-20">
        <h2 class="text-2xl font-bold">
          <span class="text-dfm-text">Functions</span>
          we support
        </h2>
        <p class="mt-2">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
          nihil fuga dolor vero. Asperiores similique assumenda praesentium?
          Suscipit culpa mollitia minima cum totam repudiandae error illum
          veritatis consequuntur, maiores sed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      functions: [
        {
          image: require("../../assets/images/functionImages/IT security.jpg"),
          title: "IT Security",
        },
        {
          image: require("../../assets/images/functionImages/marketing.jpg"),
          title: "Marketing",
        },
        {
          image: require("../../assets/images/functionImages/product management.jpg"),
          title: "Product Management",
        },
        {
          image: require("../../assets/images/functionImages/hr.jpg"),
          title: "HR",
        },
        {
          image: require("../../assets/images/functionImages/sales.jpg"),
          title: "Sales",
        },
        {
          image: require("../../assets/images/functionImages/general managemnt.jpg"),
          title: "General Management",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
