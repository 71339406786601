<template>
  <div
    class="max-w-screen-xl px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <div class="lg:flex">
      <div class="w-full mb-8 lg:w-1/2 lg:pr-20">
        <h2 class="text-2xl font-bold">
          ADEY <span class="text-dfm-text">APPOINTMENT</span>
        </h2>
        <p class="mt-4 font-semibold">
          Make easy and smooth appointments with your customers
        </p>
        <p class="mt-2">
          Adey Appointment is the easy-to-use communications solution that helps
          build personalized patient relationships, improves care outcomes,
          optimizes staff time and satisfaction, and saves money. We transform
          your patient communications while integrating seamlessly into existing
          operations
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Adey Appointment
        </button>
      </div>
      <div>
        <img src="../../assets/image_holder.png" alt="" />
      </div>
    </div>
    <div class="py-32 lg:flex">
      <div class="order-2 lg:order-1">
        <img src="../../assets/image_holder.png" alt="" />
      </div>
      <div class="order-1 w-full mb-8 lg:w-1/2 lg:pl-20 lg:order-2">
        <h2 class="mt-4 text-2xl font-bold lg:mt-0">
          ADEY <span class="text-dfm-text">GENERATOR</span>
        </h2>
        <p class="mt-4 font-semibold">
          Get the Privacy Policy with a few clicks
        </p>
        <p class="mt-2">
          On your website you need information likeLorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          labore et dolore magna aliquyam erat, sed diam voluptua.
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Adey Generator
        </button>
      </div>
    </div>
    <div class="lg:flex">
      <div class="w-full mb-8 lg:w-1/2 lg:pr-20">
        <h2 class="text-2xl font-bold">
          ADEY <span class="text-dfm-text"> ERP SYSTEM</span>
        </h2>
        <p class="mt-4 font-semibold">
          Get the Privacy Policy with a few clicks
        </p>
        <p class="mt-2">
          A comprehensive business system, enterprise resource planning (ERP)
          serves a variety of business verticals, including government
          organizations, conglomerates with complicated verticals, retail,
          trading, transportation, hospitality, real estate, and construction.
        </p>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Adey ERP System
        </button>
      </div>
      <div>
        <img src="../../assets/image_holder.png" alt="" />
      </div>
    </div>
    <div class="py-32 lg:flex">
      <div>
        <img src="../../assets/image_holder.png" alt="" />
      </div>
      <div class="w-full mt-4 mb-8 lg:w-1/2 lg:pl-20 lg:mt-0">
        <h2 class="text-2xl font-bold">
          ADEY <span class="text-dfm-text">MAIL SERVICE</span>
        </h2>
        <p class="mt-4 font-semibold">E-Mailing and Newslettering - System</p>
        <div class="mt-2">
          Send out Emails to your customer and clients to let them know what
          happens in your company. Send our the latest information and
          <h4 class="py-6 font-semibold">Mail Service</h4>
          We are providing yon with your own domain within your Emails you are
          sending out to your customers. So eveybody in the company is using the
          same email domain adress.
        </div>
        <button
          class="px-5 py-2 mt-5 text-white rounded bg-dfm-text hover:bg-orange-700"
        >
          More about Adey Mailer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
