<template>
  <nav
    class="sticky top-0 z-50 max-w-screen-xl px-5 py-2 mx-auto text-gray-700 border-b sm:px-10 2xl:px-24 border-b-gray-200 lg:flex lg:justify-between lg:items-center backdrop-blur-md"
  >
    <div class="flex items-center justify-between">
      <router-link to="/">
        <img
          src="../../assets/adey-logo.webp"
          alt="adey logo"
          class="h-[74px] w-[150px]"
        />
      </router-link>
      <button class="lg:hidden" @click="toggleBurger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            v-if="isOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
          <path
            v-if="!isOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </div>
    <div
      class="space-x-10 lg:flex lg:items-center"
      :class="isOpen ? 'block' : 'hidden'"
    >
      <div
        class="flex flex-col mt-4 space-y-2 lg:mt-0 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-10"
      >
        <router-link
          :to="menu.route"
          v-for="menu in menus"
          :key="menu.name"
          class="px-2 py-3 text-sm font-medium uppercase rounded text-dfm-menu hover:bg-slate-200"
        >
          {{ menu.name }}
        </router-link>
      </div>
      <div class="mt-4 lg:mt-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
          />
        </svg>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      menus: [
        {
          name: "Who we serve",
          route: "/who-we-serve",
        },
        {
          name: "services",
          route: "/services",
        },
        {
          name: "products",
          route: "/products",
        },
        {
          name: "About us",
          route: "/about-us",
        },
        {
          name: "Contact us",
          route: "/contact-us",
        },
      ],
    };
  },
  methods: {
    toggleBurger() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped></style>
