<template>
  <div>
    <IntroModule />
    <SelectProducts />
    <ProductsModule />
  </div>
</template>

<script>
import IntroModule from "@/components/products/IntroModule.vue";
import SelectProducts from "@/components/products/SelectProducts.vue";
import ProductsModule from "@/components/products/ProductsModule.vue";
export default {
  components: {
    IntroModule,
    SelectProducts,
    ProductsModule,
  },
};
</script>

<style lang="scss" scoped></style>
