<template>
  <div
    class="max-w-screen-md px-2 py-20 mx-auto border-b sm:px-10 2xl:px-24 border-b-gray-200"
  >
    <h1 class="text-2xl font-bold text-center uppercase">About us</h1>
    <p class="mt-4">
      With many years of experience in the German and US markets and roots in
      Ethiopia, Daniel Feseha Melesse complements his Ethiopian team perfectly.
      Young ambitious minds with diverse experience and backgrounds in market
      research, software development, cybersecurity, digital marketing and
      graphic design develop custom-fit solutions.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
